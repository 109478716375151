import { Table } from 'evergreen-ui';
import styled from 'styled-components';

export const HighlightedRow = styled(Table.Row)`
  animation: highlightNew 2s ease-out;

  @keyframes highlightNew {
    0% {
      background-color: #F7F9FD;
      opacity: 0.3;
    }
        50% {
      background-color: #EBF0F5;  // Evergreen's blue light
      opacity: 0.7;
    }
    100% {
      background-color: transparent;
    }
  }
`;