import { DateFilter } from '../types';

export const getTimeRangeFromDateFilter = (filter: DateFilter) => {
  if (filter.customRange) {
    return {
      start: filter.customRange.start,
      end: filter.customRange.end
    };
  }
  // If month is 0, we are selecting all months for the year
  if(filter.month === 0) {
    return {
      start: new Date(filter.year, 0, 1),
      end: new Date(filter.year, 11, 31)
    }
  }
  return {
    start: new Date(filter.year, filter.month - 1, 1),
    end: new Date(filter.year, filter.month, 0)
  };
};