import {
  IconButton,
  EditIcon,
  majorScale,
  TrashIcon,
  CrossIcon,
  Button,
  MoreIcon,
} from "evergreen-ui";
import { useState } from "react";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  box-shadow: none;
  background-image: none;
  background-color: inherit;
  width: 24px;
  height: 24px;
  &:hover {
    background-image: none !important;
    background-color: #ddd;
  }
`;

const Container = styled.div`
  background: inherit;
  display: flex;
  position: absolute;
`;
type MoreMenuProps = {
  onEdit?: () => any;
  onRemove?: () => any;
};
const MoreMenu = ({ onEdit, onRemove }: MoreMenuProps) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const renderMoreMenu = () => (
    <Container>
      {onEdit && (
        <IconButton
          icon={EditIcon}
          intent="none"
          marginLeft={majorScale(1)}
          onClick={() => {
            onEdit()
            setShowMoreMenu(false);
          }}
        />
      )}
      {onRemove && (
        <IconButton
          icon={TrashIcon}
          intent="danger"
          marginLeft={majorScale(1)}
          onClick={() => {
            setShowConfirmDelete(true);
            setShowMoreMenu(false);
          }}
        />
      )}
      <IconButton
        icon={CrossIcon}
        intent="none"
        marginLeft={majorScale(1)}
        onClick={() => {
          setShowMoreMenu(false);
        }}
      />
    </Container>
  );

  const renderConfirmDelete = () => (
    <Container>
      <Button intent="danger" onClick={() => {
        onRemove && onRemove();
        setShowConfirmDelete(false);
        
      }}>
        Ta bort
      </Button>
      <Button
        marginLeft={majorScale(1)}
        intent="none"
        onClick={() => setShowConfirmDelete(false)}
      >
        Ångra
      </Button>
    </Container>
  );
  return showMoreMenu ? (
    renderMoreMenu()
  ) : showConfirmDelete ? (
    renderConfirmDelete()
  ) : (
    <StyledIconButton
      icon={MoreIcon}
      onClick={() => setShowMoreMenu(true)}
    ></StyledIconButton>
  );
};
export default MoreMenu;
