import { CrossIcon, FloppyDiskIcon, IconButton, majorScale, Spinner, Table, TableRow, TextInput, TextTableCell } from 'evergreen-ui';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Project } from '../types';

interface EditProjectRowProps {
    project?: Project;
    onCancel: () => void;
    onSave: (project: Project) => void;
  }

  const EditProjectRow = ({ project, onCancel, onSave }: EditProjectRowProps) => {
    console.log('EditProjectRow rendering', { project, editingProject: project?.id });
    const [projectName, setProjectName] = useState(project?.project_name || '');

    const [error, setError] = useState(''); 
    const handleSave = () => {
        if(projectName.length === 0) {
            setError('Projektnamn är obligatoriskt');
            return;
        }
      onSave({
        ...project,
        project_name: projectName,
        // Lägg till andra obligatoriska fält här
      } as Project);
    };
    const isLoading: boolean =
    useSelector((state: any) => state.project.loadingStatus) === "loading"
  
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      setProjectName(e.currentTarget.value);
      if(e.currentTarget.value.length === 0) {
        setError('Projektnamn är obligatoriskt');
      } else {
        setError('');
      }
    };

    return (
      <TableRow>
        <TextTableCell>
          <TextInput
            isInvalid={!!error}
            type="text"
            width={"100%"}
            value={projectName}
            onChange={handleChange}
            placeholder="Projektnamn"
          />
        </TextTableCell>
        <Table.Cell justifyContent="right">
        {isLoading ? <Spinner /> :<IconButton
          icon={FloppyDiskIcon}
          intent="success"
          marginRight={majorScale(1)}
          onClick={() => handleSave()}
        />}
        <IconButton
          icon={CrossIcon}
          intent="danger"
          onClick={() => onCancel()}
        />
      </Table.Cell>
      </TableRow>
    );
  };
  
  export default EditProjectRow;