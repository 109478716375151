import React, { useState } from "react";
import {
  Table,
  TextInput,
  IconButton,
  CrossIcon,
  FloppyDiskIcon,
  majorScale,
  Combobox,
  Spinner,
} from "evergreen-ui";
import { Employee } from "../types";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import employeeSlice, {
  saveEmployee,
  saveUpdatedEmployee,
} from "./slices/employeeSlice";
interface EditEmployeeRow {
  employee: Employee;
}

const EditEmployeeRow = ({ employee }: EditEmployeeRow) => {
  const dispatch = useDispatch();

  const [firstNameInvalid, setFirstNameInvalid] = useState(false);
  const [lastNameInvalid, setLastNameInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [savedEmployeeId, setSavedEmployeeId] = useState<number>();
  const [validStatus, setValidStatus] = useState<boolean>();

  const [email, setEmail] = useState(employee.email);
  const [firstName, setFirstName] = useState(employee.firstname);
  const [lastName, setLastName] = useState(employee.lastname);
  const [status, setStatus] = useState(employee.active);

  const submitNewEmployee = (employee: Employee) => {
    if (isFormValid(employee)) {
      if (employee.id > 0) {
        dispatch(saveUpdatedEmployee(employee));
      } else {
        dispatch(saveEmployee(employee));
      }
    }
  };

  const isFormValid = (employee: Employee) => {
    let isValid = true;
    if (!/^[a-zåäö ,.'-]+$/i.test(employee.firstname)) {
      setFirstNameInvalid(true);
      isValid = false;
    } else {
      setFirstNameInvalid(false);
    }
    if (!/^[a-zåäö ,.'-]+$/i.test(employee.lastname)) {
      setLastNameInvalid(true);
      isValid = false;
    } else {
      setLastNameInvalid(false);
    }
    if (!/\S+@\S+\.\S+/.test(employee.email)) {
      setEmailInvalid(true);
      isValid = false;
    } else {
      setEmailInvalid(false);
    }
    if (isValid) {
      setValidStatus(true);
    } else {
      setValidStatus(false);
    }
    return isValid;
  };

  const preventKeyPress = (e: React.FormEvent<HTMLInputElement>) =>
    e.preventDefault();

  const statusHandler = (selected: string) =>
    selected === "Aktiv" ? setStatus(true) : setStatus(false);

  const isLoading: boolean =
    useSelector((state: any) => state.employee.loadingEmployee) === "pending";

  const saveEmployeeHandler = (id: number) => {
    setSavedEmployeeId(id);
    submitNewEmployee({
      ...employee,
      email: email,
      firstname: firstName,
      lastname: lastName,
      active: status,
    });
  };

  return (
    <Table.Row>
      <Table.TextCell>
        <TextInput
          isInvalid={firstNameInvalid}
          width={"100%"}
          value={firstName}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFirstName(e.currentTarget.value)
          }
        ></TextInput>
      </Table.TextCell>
      <Table.TextCell>
        <TextInput
          value={lastName}
          isInvalid={lastNameInvalid}
          width={"100%"}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setLastName(e.currentTarget.value)
          }
        ></TextInput>
      </Table.TextCell>
      <Table.TextCell>
        <TextInput
          value={email}
          isInvalid={emailInvalid}
          width={"100%"}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
        ></TextInput>
      </Table.TextCell>
      <Table.TextCell>
        <Combobox
          selectedItem={status ? "Aktiv" : "Inaktiv"}
          width="100%"
          openOnFocus
          onKeyPress={(e: React.FormEvent<HTMLInputElement>) =>
            preventKeyPress(e)
          }
          items={["Aktiv", "Inaktiv"]}
          onChange={(selected) => statusHandler(selected)}
          placeholder="Välj status..."
        />
      </Table.TextCell>
      <Table.Cell justifyContent="right">
        {isLoading && savedEmployeeId === employee.id && validStatus ? (
          <Spinner />
        ) : (
          <IconButton
            icon={FloppyDiskIcon}
            intent="success"
            marginRight={majorScale(1)}
            onClick={() => saveEmployeeHandler(employee.id)}
          />
        )}
        <IconButton
          icon={CrossIcon}
          intent="danger"
          onClick={() => dispatch(employeeSlice.actions.cancelNew(employee.id))}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EditEmployeeRow;
