import {
  Combobox, CrossIcon,
  FloppyDiskIcon, IconButton, majorScale, Spinner, Table,
  TextInput
} from "evergreen-ui";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../types";
import EvergreenDatePicker from '../common/Datepicker';
import { getProjectId, getProjectName } from "../helperMethods/appLibrary";
import { TimeReport } from "../types";
import { tableCellsMaxWidths } from "./layout";
import {
  saveUpdatedTimeReport
} from "./slices/timeReportSlice";
type EditTimeReportRowType = {
  timeReport: TimeReport;
  updateTimeReport: (timeReport: TimeReport) => any;
  saveTimeReport: (timeReport: TimeReport) => any;
  cancelTimeReport: (timeReport: TimeReport) => any;
  projects: Project[];
  projectFilter: number;
};

const EditTimeReportRow = ({
  timeReport,
  projects,
  updateTimeReport,
  saveTimeReport,
  cancelTimeReport,
  projectFilter
}: EditTimeReportRowType) => {
  const dispatch = useDispatch();
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isHourValid, setIsHourValid] = useState(true);
  const [isProjectValid, setIsProjectValid] = useState(true);

  const [savedTimeReportId, setSavedTimeReportId] = useState<number>()
  const [validStatus, setValidStatus] = useState<boolean>()
  const isLoading: boolean =
    useSelector((state: any) => state.timeReport.loadingTimeReport) === "loading"

  const [date, setDate] = useState(timeReport.time);
  const [description, setDescription] = useState(timeReport.description);
  const [hours, setHours] = useState(timeReport.hours);
  const [project, setProject] = useState(getProjectName(timeReport.project_id, projects) || getProjectName(projectFilter, projects));
 
  const submitTimeReport = (timeReport: TimeReport) => {
    if (isFormValid(timeReport)) {
      if (timeReport.id > 0) {
        dispatch(saveUpdatedTimeReport(timeReport));
      } else {
        saveTimeReport(timeReport);
      }
    }
  };

  const isFormValid = (timeReport: TimeReport) => {
    let isValid = true;
    if (!timeReport.description) {
      setIsDescriptionValid(false);
      isValid = false;
    } else {
      setIsDescriptionValid(true);
    }

    if (timeReport.hours < 1) {
      setIsHourValid(false);
      isValid = false;
    } else {
      setIsHourValid(true);
    }

    if (!timeReport.project_id) {
      setIsProjectValid(false);
      isValid = false;
    } else {
      setIsProjectValid(true);
    }
    if (isValid) {
      setValidStatus(true)
    }else{
      setValidStatus(false)
    }
    return isValid;
  };

  const hourHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (Number(e.currentTarget.value) >= 0) {
      setHours(Number(e.currentTarget.value))
    }
  }

  const updateProjectToState = (projectName: string) => {
    const project = projects.find(
      (project: Project) => project.project_name === projectName
    );

    if (project) {
      updateTimeReport({ ...timeReport, project_id: project.id });
    }
  };

  const saveTimeReportHandler = (id:number) => {
    setSavedTimeReportId(id)
    submitTimeReport({ ...timeReport, time: date, description: description, hours: hours, project_id: getProjectId(project, projects) })
  }
  
  return (
    <Table.Row key={timeReport.id}>
      <Table.Cell maxWidth={tableCellsMaxWidths.date}>
        <EvergreenDatePicker
          selectedTime={date}
          onChange={(date) => setDate(date)}
          maxDate={new Date()}
        />

      </Table.Cell>
      <Table.Cell>
        <TextInput
          isInvalid={!isDescriptionValid}
          width="100%"
          value={description}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setDescription(event.currentTarget.value)
          }
        />
      </Table.Cell>
      <Table.Cell maxWidth={tableCellsMaxWidths.hours}>
        <TextInput
          isInvalid={!isHourValid}
          type="number"
          width="100%"
          value={hours ? hours : ""}
          onChange={(e: React.FormEvent<HTMLInputElement>) => hourHandler(e)}
        />
      </Table.Cell>
      <Table.Cell maxWidth={tableCellsMaxWidths.project}>
        <Combobox
          width="100%"
          openOnFocus
          items={projects.map((projects) => projects.project_name)}
          onChange={(selected) => setProject(selected)}
          placeholder="Välj projekt..."
          selectedItem={project}
        />
      </Table.Cell>
      <Table.Cell maxWidth={tableCellsMaxWidths.options}>
        { isLoading && savedTimeReportId === timeReport.id && validStatus ? <Spinner /> : <IconButton
          icon={FloppyDiskIcon}
          intent="success"
          marginRight={majorScale(1)}
          onClick={() => saveTimeReportHandler(timeReport.id)} 
        />}
        <IconButton
          icon={CrossIcon}
          intent="danger"
          onClick={() => cancelTimeReport(timeReport)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EditTimeReportRow;