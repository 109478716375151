import { Project } from '../types';

export const getProjectsByUser = (jwtToken: string, email: string) => {

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/${email}/project-list`, {
       headers: { authorization: `bearer ${jwtToken}` },
   }).then((res: any) => res.json());
};
export const getSingleProjectDetails = async (jwtToken: string, projectId: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project/${projectId}`, {
    headers: { authorization: `bearer ${jwtToken}` },
  });
  const result = await response.json();
  if(!response.ok){
    throw new Error(result.errors?.[0]?.msg || 'Failed to get project details');
  }
  return result.data;
};

export const getAllProjects = (jwtToken: string) => {

   return fetch(`${process.env.REACT_APP_API_BASE_URL}/project-list`, {
      headers: { authorization: `bearer ${jwtToken}` },
  }).then((res: any) => res.json());
};

export const postProject = async (jwtToken: string, project: Project) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project`, {
    method: 'POST',
    body: JSON.stringify(project),
    headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.errors?.[0]?.msg || 'Failed to create project');
  }
  return data;
};

export const deleteProject = async (jwtToken: string, projectId: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project/${projectId}`, {
    method: 'DELETE',
    headers: { authorization: `bearer ${jwtToken}` },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.errors?.[0]?.msg || 'Failed to delete project');
  }
  return data;
};
export const updateProject = async (jwtToken: string, project: Project) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project/${project.id}`, {
    method: 'PUT',
    body: JSON.stringify(project),
    headers: { authorization: `bearer ${jwtToken}`, 'Content-Type': 'application/json' },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.errors?.[0]?.msg || 'Failed to update project');
  }
  return data;
};
export const postEmployeeToProject = async (jwtToken: string, projectId: number, employeeId: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project/${projectId}/employee/${employeeId}`, {
    method: 'POST',
    headers: { authorization: `bearer ${jwtToken}` },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.errors?.[0]?.msg || 'Failed to add employee to project');
  }
  return data;
};
export const deleteEmployeeFromProject = async (jwtToken: string, projectId: number, employeeId: number) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/project/${projectId}/employee/${employeeId}`, {
    method: 'DELETE',
    headers: { authorization: `bearer ${jwtToken}` },
  });
    const data = await response.json();
  if (!response.ok) {
    throw new Error(data.errors?.[0]?.msg || 'Failed to delete employee from project');
  }
  return data;
};
