import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import { TextInput } from "evergreen-ui";

type EvergreenDatePickerProps = {
    selectedTime: Date;
    onChange: (date: Date) => any;
    maxDate?: Date;
  };
  const EvergreenDatePicker = ({
    selectedTime,
    onChange,
    maxDate,
  }: EvergreenDatePickerProps) => {
    const ExampleCustomInput = forwardRef(({ onClick, value }: any, ref: any) => (
      <TextInput ref={ref} value={value} onClick={onClick} width="100%" />
    ));
    return (
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={selectedTime}
        onChange={(date: any) => onChange(date)}
        customInput={<ExampleCustomInput />}
        maxDate={maxDate}
      />
    );
  };

export default EvergreenDatePicker;