import { Combobox } from "evergreen-ui";
import { useState, useRef, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DateFilter } from "../../types/index";
import { TabFilter } from "../TabFilter";
import { DefaultTab, TabContainer, Wrapper } from './styles';
import { allMonths, YearMonths } from "./types";
import { format } from 'date-fns';
import { addDays } from 'date-fns';

type TimespanSelectorProps = {
  onChange: (filter: DateFilter) => void;
  yearMonths: YearMonths;
  filter: DateFilter;
  selectAllMonth?: boolean;
  enableCustomRange?: boolean;
};

export const TimespanSelector = ({
  onChange,
  yearMonths,
  filter,
  selectAllMonth = true,
  enableCustomRange = false
}: TimespanSelectorProps) => {

  const calendarRef = useRef<HTMLDivElement>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isCustomRange, setIsCustomRange] = useState(!!filter.customRange);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const years = yearMonths && Object.keys(yearMonths);
  // TODO - currentYear skall inte vara hårdkodat..
  const currentYear = 2021;
  // TODO - varför har vi hårdkodat 2021 här ?? 
  const months = (yearMonths && yearMonths[filter.year]) ?? (yearMonths && yearMonths[currentYear]);
  const selectedYear = filter.year ?? currentYear;
  const selectedMonth = filter.month ?? 0;
  const selectedMonthIndex = months && months.indexOf(selectedMonth); //TODO: Har kraschat här
  
  const handleClickOutside = (event: MouseEvent) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
      setShowCalendar(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setCustomDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start || new Date());
    setEndDate(end);
    
    if (start && end) {
      setIsCustomRange(true);
      onChange({
        year: start.getFullYear(),
        month: 0,
        customRange: { start, end: addDays(end, 1) }
      });
      setShowCalendar(false);
    }
  };
  const clearCustomRange = () => {
    setIsCustomRange(false);
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <Wrapper
      clearfix
    >
      <TabContainer>
        {years && <Combobox
          initialSelectedItem={{ label: selectedYear.toString() }}
          items={years.map(year => ({ label: year }))}
          itemToString={item => (item ? item.label : '')}
          onChange={selected => {
            clearCustomRange()
            onChange({ ...filter, year: Number(selected.label), customRange: undefined })
          }}
        />

        }
      </TabContainer>
      <TabContainer
        float="right"
      >
        {months && <TabFilter
          selectAllOption={selectAllMonth}
          items={months.map(month => allMonths[month - 1])}
          onSelect={(index: number, value: any) => {
            clearCustomRange();
            onChange({ ...filter, month: allMonths.indexOf(value) + 1 , customRange: undefined })
          }}
          selectedIndex={selectedMonthIndex}
        />}
           {enableCustomRange && (
          <DefaultTab
            isSelected={isCustomRange}
            onSelect={() => setShowCalendar(!showCalendar)}
          >
            {startDate && endDate ? `${format(startDate, "yyyy-MM-dd")} - ${format(endDate, "yyyy-MM-dd")}` : "Anpassat"}
          </DefaultTab>
        )}
      </TabContainer>
      {enableCustomRange && showCalendar && (
          <div
          ref={calendarRef}
          style={{
            position: "absolute",
            right: "0",
            zIndex: 1,
          }}
        >
          <ReactDatePicker
            selected={startDate}
            onChange={setCustomDateRange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
    </Wrapper>
  );
};
