import { Alert, Button, CrossIcon, IconButton, Pane, Table, TableRow } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch, RootState } from '../app/store';
import { ErrorMessage } from "../common/ErrorMessage/ErrorMessage";
import { ViewWrapper } from "../common/ViewWrapper";
import { Employee, Project } from "../types";
import AddEmployeeToProjectRow from './addEmployeeToProjectRow';
import projectSlice, { addEmployeeToProject, fetchSingleProjectDetails, removeEmployeeFromProject } from "./slices/projectSlice";

const ProjectDetailsView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAdmin = useSelector((state: RootState) => state.authentication.isAdmin);
  const [showAddEmployeeForm, setShowAddEmployeeForm] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const project : Project | null = useSelector((state: RootState) => state.project.currentProject);
  const error = useSelector((state: RootState) => state.project.error);
  const employees = useSelector((state: RootState) => state.employee.employees);
  const allActiveEmployees = employees.filter(employee => employee.active);
  useEffect(() => {
    if(projectId && isAdmin) {
      dispatch(fetchSingleProjectDetails(Number(projectId))); 
    }
  }, [dispatch, projectId, isAdmin]);

  const handleAddEmployee = async (employeeId: number) => {
    try {
      await dispatch(addEmployeeToProject({ projectId: Number(projectId), employeeId })).unwrap();
      setShowAddEmployeeForm(false);
    } catch (error) {
      console.error('Error adding employee to project:', error);
    }
  };

  const handleRemoveEmployee = (employeeId: number) => {
    dispatch(removeEmployeeFromProject({ project_id: Number(projectId), employee_id: employeeId }));
  };
  const renderButtons = () =>
    (
      <Button
        height={32}
        appearance="primary"
        disabled={false}
        intent="success"
        onClick={() =>
          setShowAddEmployeeForm(true)
        }
      >
        Lägg till
      </Button>
    )

  return ( 
      <>{ project ?
      <ViewWrapper renderButtons={renderButtons} title={project.project_name}> 
        <Pane
        clearfix
        display="block"
        borderBottom="1px dotted #ccc"
        marginTop="10px"
      />
         {error && 
      <div style={{position: 'fixed', bottom: '20px', right: '20px', zIndex: 10}}>
        <IconButton style={{
          position: 'absolute',
          top: '2px',
          right: '2px',
          zIndex: 100,
          backgroundColor: 'inherit',
          border: 'none', 
          boxShadow: 'none',
        }} icon={CrossIcon} onClick={() => dispatch(projectSlice.actions.clearError())}></IconButton>
        <Alert intent="danger" title="Något gick fel">
        {error}
        </Alert>
      </div>
      }
      <Table>
      <Table.Head style={{display: 'flex', padding: 0}}>
        <Table.TextHeaderCell >Kopplade konsulter</Table.TextHeaderCell>
        <Table.TextHeaderCell style={{ textAlign: 'right'}}>Åtgärder</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body overflowY="visible">
        {showAddEmployeeForm && <AddEmployeeToProjectRow selectableEmployees={allActiveEmployees.filter(employee => !project.employees.some(pEmployee => pEmployee.id === employee.id))} onSave={(employee: Employee) => handleAddEmployee(employee.id)} onCancel={() => setShowAddEmployeeForm(false)} />}
        {project.employees.map(employee => (
          <TableRow key={employee.id}>
            <Table.TextCell >{`${employee.firstname} ${employee.lastname}`}</Table.TextCell>
            <Table.Cell style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={() => handleRemoveEmployee(employee.id)}>Ta bort</Button>
            </Table.Cell>
          </TableRow>
        ))}
      </Table.Body>
    </Table>
      </ViewWrapper> 
      : <ErrorMessage title={`Projektid ${projectId} finns inte, prova nåt annat!`}/>}
      </>);
};

export default ProjectDetailsView;
//<Button onClick={() => handleAddEmployee(/* employeeId */)}>Lägg till</Button>


