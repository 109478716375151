export type Transaction = {
  id?: number,
  email: string;
  time: Date;
  amount?: number;
  description: string;
  sum?: number;
  editMode?: boolean;
  sourceReference?: string;
  status: TransactionStatus;
};

export type Employee = {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  active: boolean;
  editMode?: boolean;
};


export enum TransactionStatus {
  Final,
  Preliminary,
  Rejected,
  New
}

export type TimeReport = {
  id: number,
  email: string;
  time: Date;
  description: string;
  hours: number;
  project_id: number;
  editMode?: boolean;
  isNew?: boolean;
};

export type NewTimeReport = {
  id: number,
  email: string;
  time: Date;
  hours?: number;
  description: string;
  project_id: number;
  editMode?: boolean;
};

export type DateFilter = {
  year: number;
  month: number;
  customRange?: {start: Date, end: Date}
};

export type TransactionFilter = {
  year: number;
  month: number;
  description?: string;
};
export type Project = {
  id: number;
  project_name: string;
  employees: Employee[];
};
export type EmployeeProject = {
  project_id: number;
  employee_id: number;
}