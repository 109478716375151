import { Button, CrossIcon, IconButton, Pane, Table, TableRow } from "evergreen-ui";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../app/store';
import { ViewWrapper } from "../common/ViewWrapper";
import { Employee, Project } from "../types";
import isAdminSelector from '../utils/isAdminSelector';
import EditProjectRow from './editProjectRow';
import projectSlice, { editProject, removeProject, saveProject } from './slices/projectSlice';
import MoreMenu from '../common/MoreMenu/MoreMenu';
import { Alert } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
type ProjectViewType = {
  user: Employee;
}

const ProjectListView = ({ user }: ProjectViewType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(isAdminSelector)
  const [editingProject, setEditingProject] = useState<number | undefined>();
  //Hämtar alla projekt i projects  
  const projects: Project[] = useSelector((state: RootState) => state.project.allProjects);
  const error = useSelector((state: RootState) => state.project.error);
  const isLoading = useSelector((state: RootState) => 
    state.project.loadingStatus === 'loading'
  );
const handleProjectClick = (projectId: number) => {
  if(isAdmin) {
    navigate(`/project/${projectId}`);
  }
};
  const renderButtons = () =>
    isAdmin && (
      <Button
        height={32}
        appearance="primary"
        disabled={isLoading || editingProject !== undefined}
        intent="success"
        onClick={() =>
          setEditingProject(-1)
        }
      >
        Lägg till
      </Button>
    )
  return (

    <ViewWrapper title="Projekt" renderButtons={renderButtons}>
      {error && 
      <div style={{position: 'fixed', bottom: '20px', right: '20px', zIndex: 10}}>
        <IconButton style={{
          position: 'absolute',
          top: '2px',
          right: '2px',
          zIndex: 100,
          backgroundColor: 'inherit',
          border: 'none',
          boxShadow: 'none',
        }} icon={CrossIcon} onClick={() => dispatch(projectSlice.actions.clearError())}></IconButton>
        <Alert intent="danger" title="Något gick fel">
        {error}
        </Alert>
      </div>
      }
      <Pane
        clearfix
        display="block"
        borderBottom="1px dotted #ccc"
        marginTop="10px"
      />
          {editingProject === -1 && (
            <EditProjectRow
              onSave={(project) => {
                dispatch(saveProject(project));
                setEditingProject(undefined);
              }}
              onCancel={() => setEditingProject(undefined)}
            />
          )}
      {projects.map((project) => project.id === editingProject ? (<EditProjectRow
                key={project.id}
                project={project}
                onSave={(updatedProject) => {
                  dispatch(editProject(updatedProject));
                  setEditingProject(undefined);
                }}
                onCancel={() => setEditingProject(undefined)}
              />) :
      <TableRow style={{cursor: 'pointer'}} key={project.id}>
        <Table.TextCell onClick={() => handleProjectClick(project.id)}>{project.project_name}</Table.TextCell>
      {isAdmin && ( 
        <Table.Cell
        justifyContent="right"
        width="10px">
              <MoreMenu
                onEdit={() =>
                 setEditingProject(project.id)
                }
                onRemove={() =>dispatch(removeProject(project.id))}
              ></MoreMenu>
              </Table.Cell>
            )}</TableRow>)}

      <Pane
        clearfix
        display="block"
        borderBottom="1px dotted #ccc"
        marginTop="10px"
      />

    </ViewWrapper >
  );
};

export default ProjectListView;
