type YearMonth = {
    year: number;
    month: number;
  };
  
  export const getLatestYearMonth = (meta: any): YearMonth => {
    const year = Math.max(...Object.keys(meta).map(Number));
    const month = Math.max(...meta[year]);
  
    return { year, month };
  };