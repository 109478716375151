import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  getTimeReportsByUser,
  getTimeReportsMeta,
  postTimeReport,
  deleteTimeReport,
  getTimeReportsByProject,
  updateTimeReport,
} from "../../api/crudTimeReport"

// importera från types
import { TimeReport, DateFilter, Employee } from "../../types/index"
import { RootState } from '../../app/store'
import { getTimeRangeFromDateFilter } from '../../utils/getTimeRangeFromDateFilter'
import { getLatestYearMonth } from '../../utils/getLatestYearMonth'

// create fetch TimeReport + TimreReportMeta
// jwtToken: string, email: string, year?: number, month?: number

// TODO skapa fetchTimeReportsByProject samt reducer som uppdaterar statet med den fetchade datan (.fullfilled)
export const fetchTimeReportsByProject: any = createAsyncThunk(
  "timereport/fetch",
  async (project: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { start, end } = getTimeRangeFromDateFilter(state.timeReport.filter);
    return await getTimeReportsByProject(
      state.authentication.jwtIdToken,
      project.id,
     start,
     end
    )
  }
)

export const fetchTimeReportsByUser: any = createAsyncThunk(
  "timereport/fetch",
  async (user: Employee, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { start, end } = getTimeRangeFromDateFilter(state.timeReport.filter);
    return await getTimeReportsByUser(
      state.authentication.jwtIdToken,
      user.email,
      start,
     end
    )
  }
)

export const fetchTimeReportsMeta: any = createAsyncThunk(
  "timereportmeta/fetch",
  async (user: any, thunkAPI) => {
    const state: any = thunkAPI.getState()
    return await getTimeReportsMeta(state.authentication.jwtIdToken, user.email)
  }
)

export const saveNewTimeReport: any = createAsyncThunk<any, TimeReport>(
  "timereport/create",
  async ({ user, timeReport }: any, thunkAPI) => {
    const state: any = thunkAPI.getState()

    const response = await postTimeReport(state.authentication.jwtIdToken, {
      ...timeReport,
      email: user.email,
      time: timeReport.time.toDateString(),
    })
    return response
  }
)
export const saveUpdatedTimeReport: any = createAsyncThunk<any, TimeReport>(
  "timereport/update",
  async (timeReport: any, thunkAPI) => {
    const state: any = thunkAPI.getState()
    const response: TimeReport = await updateTimeReport(
      state.authentication.jwtIdToken,
      {
        ...timeReport,
        time: timeReport.time.toDateString(),
      }
    )
    return response
  }
)

export const removeTimeReport: any = createAsyncThunk<any, TimeReport>(
  "timereport/remove",
  async ({ user, timeReport }: any, thunkAPI) => {
    const state: any = thunkAPI.getState()
    const response = await deleteTimeReport(
      state.authentication.jwtIdToken,
      timeReport
    )
    //thunkAPI.dispatch(fetchTimeReports({ email: user.email }));
    return response
  }
)

type TimeReportState = {
  entities: TimeReport[]
  loadingTimeReports: string
  loadingTimeReport: string
  filter: DateFilter
  sort: "desc" | "asc"
  meta?: any
}

// Then, handle actions in your reducers:
const timeReportSlice = createSlice({
  name: "timereports",
  initialState: {
    entities: [],
    loadingTimeReports: "idle",
    loadingTimeReport: "idle",
    filter: { 
      year: new Date().getFullYear(), 
      month: new Date().getMonth() + 1  // +1 eftersom månader är 1-baserade i UI
    },
    sort: "desc",
    meta: undefined,
  } as TimeReportState,
  reducers: {
    setFilter(state, action: PayloadAction<DateFilter>) {
      const year = action.payload.year;
      const month = action.payload.month === 0 ? 0 : 
        (state.meta[year]?.indexOf(action.payload.month) > -1 
          ? action.payload.month 
          : Math.max(...(state.meta[year] || [])));
    
      state.filter = { 
        year, 
        month,
        customRange: action.payload.customRange  // Behåll customRange för timeReport
      };
    },
    toggleSort(state) {
      state.sort = state.sort === "desc" ? "asc" : "desc"
    },
    addNew(state, action: PayloadAction<Date>) {
      const newTimeReport = {
        time: action.payload,
        description: "",
        hours: 0,
        id: -1,
        email: "",
        project_id: 0,
        editMode: true,
      }
      state.entities.unshift(newTimeReport)
    },
    cancelNew(state, action: PayloadAction<TimeReport>) {
      if (action.payload.id === -1) {
        state.entities = [...state.entities].filter(
          (timereport) => timereport.id !== -1
        )
      } else {
        state.entities.forEach((timereport) =>
          timereport.editMode && timereport.id === action.payload.id
            ? delete timereport.editMode
            : null
        )
      }
    },
    updateTimeReport(state, action: PayloadAction<TimeReport>) {
      state.entities = state.entities.map((timereport) =>
        timereport.id === action.payload.id ? action.payload : { ...timereport }
      )
    },
    editMode(state, action: PayloadAction<number>) {
      state.entities = state.entities.map((timereport) =>
        timereport.id === action.payload
          ? { ...timereport, editMode: true }
          : { ...timereport }
      )
    },
    clearNewFlag(state, action: PayloadAction<number>) {
      const report = state.entities.find(r => r.id === action.payload);
      if (report) {
        delete report.isNew 
      }
    }
  },

  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchTimeReportsByUser.fulfilled]: (state, action) => {
      state.entities = action.payload.map((timeReport: any) => ({
        ...timeReport,
        time: new Date(timeReport.time),
      }))
      state.loadingTimeReports = "complete"
    },
    [fetchTimeReportsByUser.pending]: (state, action) => {
      state.loadingTimeReports = "loading"
    },
    //TODO: uppdaterar statet med aktuell data för att få in alla timrapporter från ett valt projekt.
    //måste stå något här för annars hämtas inte datan med timereports!
    [fetchTimeReportsByProject.fulfilled]: (state, action) => {
      state.entities = action.payload.map((timeReport: any) => ({
        ...timeReport,
        time: new Date(timeReport.time),
      }))
      state.loadingTimeReports = "complete"
    },
    [fetchTimeReportsMeta.fulfilled]: (state, action) => {
      state.meta = action.payload.reduce(
        (pV: any, cV: any) =>
          !pV[cV.year]
            ? { ...pV, [cV.year]: [Number(cV.month)] }
            : { ...pV, [cV.year]: [...pV[cV.year], Number(cV.month)] },
        {}
      )
      const { year, month } = getLatestYearMonth(state.meta);
  state.filter = { year, month };
    },
    [fetchTimeReportsMeta.pending]: (state, action) => {
      //state.loading = "loading";
    },
    [saveNewTimeReport.fulfilled]: (state, action) => {
      const timeReportTime = new Date(action.payload.time);
      const year = timeReportTime.getFullYear();
      const month = timeReportTime.getMonth() + 1;


      if (!state.meta[year]) {
        state.meta[year] = [month];
      } else {
        if (!state.meta[year].some((m: number) => m === month)) {
          state.meta[year] = [...state.meta[year], month].sort((a:number, b:number) => a-b);
        }
      }
      state.filter.year = year;
      if (state.filter.month !== 0) {
        state.filter.month = month;
      } 
      if ((state.filter.month === month && state.filter.year === year) || state.filter.month === 0) {
        state.entities = [...state.entities, {...action.payload, time: new Date(action.payload.time), isNew: true}].filter(
          (timereport) => timereport.id !== -1
          )

      }
      state.loadingTimeReport = "complete"
    },

    [saveNewTimeReport.pending]: (state, action) => {
      state.loadingTimeReport = "loading"
    },

    [saveUpdatedTimeReport.pending]: (state) => {
      state.loadingTimeReport = "loading"
    },
    [saveUpdatedTimeReport.fulfilled]: (state, action) => {
      const timeReportTime = new Date(action.payload.time);
      const year = timeReportTime.getFullYear();
      const month = timeReportTime.getMonth() + 1;

      if (!state.meta[year]) {
        state.meta[year] = [month];
      } else {
        if (!state.meta[year].some((m: number) => m === month)) {
          state.meta[year] = [...state.meta[year], month].sort((a:number, b:number) => a-b);
        }
      }
      state.filter.year = year;
      if (state.filter.month !== 0) {
        state.filter.month = month;
      }
      if ((state.filter.month === month && state.filter.year === year) || state.filter.month === 0) {
        state.entities = [...state.entities, {...action.payload, time: new Date(action.payload.time)}].filter(
          (timereport) => !timereport.editMode || timereport.id !== action.payload.id
          )
      }
      state.loadingTimeReport = "complete"
    },

    [removeTimeReport.fulfilled]: (state, action) => {
      state.entities = [...state.entities].filter(
        (timereport) => timereport.id !== action.payload.id
      )
    },
  },
})

export default timeReportSlice
