import { Button, Spinner, Table, TableRow, TextInput, TextTableCell, IconButton, FloppyDiskIcon, majorScale, CrossIcon } from 'evergreen-ui';
import {Autocomplete} from '../common/Autocomplete/';
import { Employee } from '../types';
import { useState } from 'react';
interface AddEmployeeToProjectRowProps {            
    onSave: (employee: Employee) => void;
    onCancel: () => void;
    selectableEmployees: Employee[];
}
const AddEmployeeToProjectRow = ({ onSave, onCancel, selectableEmployees }: AddEmployeeToProjectRowProps) => {
    const isLoading = false;
    const [selectedEmployee, setSelectedEmployee] = useState('');
   const optionsAsStringArray = selectableEmployees.map(employee => `${employee.firstname} ${employee.lastname}`);

   
   const handleSave = () => {
    const selectedEmployeeObject = selectableEmployees.find(employee => employee.firstname + ' ' + employee.lastname === selectedEmployee);
    if (selectedEmployeeObject) {
        onSave(selectedEmployeeObject);
    }
   }
  return (
    <div>
    <TableRow>
        <Table.Cell overflowY="visible" overflowX="visible">
          <div style={{position: 'relative', zIndex: 999}}>
        <Autocomplete options={optionsAsStringArray} setValue={(value) => setSelectedEmployee(value)} value={selectedEmployee} />
          </div>
            </Table.Cell>
        <Table.Cell justifyContent="right">
          {isLoading ? <Spinner /> : <IconButton 
          icon={FloppyDiskIcon} 
          intent='success'
          disabled={!optionsAsStringArray.includes(selectedEmployee)}
          marginRight={majorScale(1)}
          onClick={() => handleSave()}>Lägg till</IconButton>
          }
          <IconButton icon={CrossIcon} intent='danger' onClick={() => onCancel()} />
        </Table.Cell>
    </TableRow>
    </div>
  )
}

export default AddEmployeeToProjectRow;